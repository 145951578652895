import "reflect-metadata";

import { createApp } from 'vue';

import App from './App.vue';
import store from './store';
import router from './router';
import './registerServiceWorker';
import Vue3Lottie from 'vue3-lottie';
import 'vue3-toastify/dist/index.css';
import { firebaseApp } from './firebase';

import VueGoogleMaps from '@fawmi/vue-google-maps';

/* eslint-disable */
// @ts-ignore
import { VueFire, VueFireAuth } from 'vuefire';
/* eslint-enable */

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import "@mdi/font/css/materialdesignicons.css";

const vuetify = createVuetify({
	components,
	directives,
	icons: {
		defaultSet: 'mdi'
	},
});

createApp(App)
    .use(store)
    .use(router)
	.use(vuetify)
	.use(VueGoogleMaps, {
		load: {
			v: 'weekly',
			key: 'AIzaSyB7qVOXPkPrACICw1Ec91qn31n4GeIi7tE',
			language: 'en',
			libraries: "places",
			loading: 'async',
			autobindAllEvents: true,
		},
	})
    .use(VueFire, {
		firebaseApp,
		modules: [
			VueFireAuth(),
		],
	})
	.use(Vue3Lottie, {
		name: "Vue3Lottie"
	})
    .mount('#app');