import { 
  createRouter, 
  createWebHistory, 
  RouteRecordRaw 
} from 'vue-router';

/* eslint-disable */
//@ts-ignore
import { getCurrentUser } from 'vuefire';
/* eslint-enable */

import { viewLazyLoad } from '@/utils/lazyLoadUtil';

// Routes// Routes
import { authRoutes } from './authRoutes';
import { dashboardRoutes } from './dashboardRoutes';

const routes: Array<RouteRecordRaw> = [
  ...authRoutes,
  ...dashboardRoutes,
  {
    path: "/:404",
    component: viewLazyLoad('NotFound')
  },
  {
    path: "/:pathMatch(.*)*",
    component: viewLazyLoad('NotFound')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to) => {
  const currentUser = await getCurrentUser();

  if (to.meta.requiresAuth) {
    if (!currentUser)
      return {
        name: "signin",
        query: {
          redirect: to.fullPath,
        },
      }
  }

  if (currentUser && to.name === "signin")
    return {
      name: "campaigns"
    }
});

export default router;
