import {
  GetterTree,
  ActionTree,
  createStore,
  MutationTree
} from 'vuex';

export const state = () => ({});

export type RootState = ReturnType<typeof state>;

const getters: GetterTree<RootState, RootState> = {};

export const mutations: MutationTree<RootState> = {};

export const actions: ActionTree<RootState, RootState> = {};

export default createStore({
  state,
  mutations,
  actions,
  getters,
});