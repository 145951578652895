<template>
  <v-layout>
    <v-app>
      <router-view/>
    </v-app>
  </v-layout>
</template>

<style lang="scss">
@import '@/assets/scss/reset';
@import '@/assets/scss/default';
</style>