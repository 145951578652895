import { RouteRecordRaw } from 'vue-router';

import { viewLazyLoad } from '@/utils/lazyLoadUtil';

export const authRoutes: Array<RouteRecordRaw> = [
    {
        name: 'signin',
        path: '/signin',
        component: viewLazyLoad('Signin')
    }
];